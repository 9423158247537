<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Complimentary enrolment
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Complimentary enrolment
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row" v-if="show_summary_flag">
              <v-col cols="12" sm="4"  v-if="candidates.length>0" v-for="candidate in candidates">
                <v-card >
                  <div class="form-group row">
                    <label class="col-xl-12 col-lg-12 col-form-label">Candidate  information
                    </label>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Full name:</label>
                    <div class="col-lg-8 col-xl-6">
                    <span class="font-weight-bold">{{candidate.full_name}}
                    </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Enrolment key:</label>
                    <div class="col-lg-8 col-xl-6">
                      <span class="font-weight-bold">{{candidate.exam_key}}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Enrolment pin:</label>
                    <div class="col-lg-8 col-xl-6">
                      <span class="font-weight-bold">{{candidate.exam_pin}}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Exam name:</label>
                    <div class="col-lg-8 col-xl-6">
                      <span class="font-weight-bold">{{candidate.exam_name ? candidate.exam_name : 'NA' }}
                    </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Date of birth:</label>
                    <div class="col-lg-8 col-xl-6">
                    <span class="font-weight-bold">{{candidate.formatted_date_of_birth}}
                    </span>
                    </div>
                  </div>

                </v-card>
              </v-col>
            </div>
            <v-row v-else>
              <v-col cols="12" md="3">
                <v-text-field  dense outlined label="Number of candidates" v-model="no_of_candidate" @keypress="isNumber($event)" @blur="limitMax" :error="continue_flag && $v.no_of_candidate.$error">
                  <template v-slot:label>
                    Number of candidates <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="continue_flag && $v.no_of_candidate.$error">This information is required</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-select @change="changeType" dense outlined label="Product type" :items="types" item-text="text" item-value="value" v-model="type" :error="continue_flag && $v.type.$error">
                  <template v-slot:label>
                    Product type <span class="text-danger">*</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-select>
                <span class="text-danger" v-if="continue_flag && $v.type.$error">This information is required</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete item-text="name" placeholder="Start typing the product name and click to select"
                                item-value="id"
                                v-model="selectProduct" :items="products" :search-input.sync="productSearch" :error="continue_flag && $v.selectProduct.$error"
                                outlined dense label="Product name"  clearable return-object>
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>

                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                  <template v-slot:label>
                    Product name <span class="text-danger">*</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-autocomplete>
                <span class="text-danger" v-if="continue_flag && $v.selectProduct.$error">This information is required</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-select  dense outlined label="Class identifier" :items="class_exams" item-value="value" item-text="text" v-model="is_class_exam">
                  <template v-slot:label>
                    Class identifier <span class="text-danger">*</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn class="text-white btn btn-primary mt-1" @click="addMoreCandidate" v-if="show_summary_flag">
                  Add candidate
                </v-btn>
                <v-btn x-large dark @click="continueCandidate" v-else>
                  Continue
                </v-btn>

              </v-col>
            </v-row>
            <v-row v-if="rows.length>0">
              <v-col cols="12" md="12" v-for="(item,index) in rows">

                <v-row>
                  <v-col cols="12">
                   <span class="font-weight-medium title">{{index+1}}. Candidate information</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field label="First name" dense outlined v-model="item.first_name" :error="finish_flag && $v.rows.$each[index].first_name.$error">
                      <template v-slot:label>
                        First name <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].first_name.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field label="Middle name" dense outlined v-model="item.middle_name"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field label="Surname" dense outlined v-model="item.last_name" :error="finish_flag && $v.rows.$each[index].last_name.$error">
                      <template v-slot:label>
                        Surname <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].last_name.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select label="Day of birth" dense outlined v-model="item.day" :items="days" :error="finish_flag && $v.rows.$each[index].day.$error">
                      <template v-slot:label>
                        Day of birth <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].day.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select label="Month of birth" dense outlined v-model="item.month" :items="months" item-text="text" item-value="value" :error="finish_flag && $v.rows.$each[index].month.$error">
                      <template v-slot:label>
                        Month of birth <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].month.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select label="Year of birth" dense outlined v-model="item.year" :items="years" item-value="value" item-text="text" :error="finish_flag && $v.rows.$each[index].year.$error">
                      <template v-slot:label>
                        Year of birth <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].year.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="3" v-if="scoreDetail && scoreDetail.gender_identity">
                    <v-select :error="finish_flag && $v.rows.$each[index].gender.$error"  @change="changeGender(index)" label="Gender" dense outlined v-model="item.gender" :items="genders" item-text="text" item-value="value">
                      <template v-slot:label>
                        Gender <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].gender.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="3" v-if="scoreDetail && scoreDetail.gender_identity && item.gender=='self-described'">
                    <v-text-field label="Self-described gender" dense outlined v-model="item.gender_self_described" :error="finish_flag && $v.rows.$each[index].gender_self_described.$error">
                      <template v-slot:label>
                        Self-described gender <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].gender_self_described.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="3" v-if="scoreDetail && scoreDetail.gender_identity && scoreDetail.preferred_pronoun_enabled && item.preferred_pronouns_flag">
                    <v-select label="Preferred pronouns" :items="preferred_pronouns" dense outlined v-model="item.preferred_pronouns"  item-text="text" :error="finish_flag && $v.rows.$each[index].preferred_pronouns.$error" item-value="value">
                      <template v-slot:label>
                        Preferred pronouns <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].preferred_pronouns.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12" md="3" v-if="scoreDetail && scoreDetail.gender_identity && scoreDetail.preferred_pronoun_enabled && item.preferred_pronouns_flag && item.preferred_pronouns=='self-described'">
                    <v-text-field label="Self-described pronouns" dense outlined v-model="item.prefer_to_described" :error="finish_flag && $v.rows.$each[index].prefer_to_described.$error">
                      <template v-slot:label>
                        Self-described pronouns <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="finish_flag && $v.rows.$each[index].prefer_to_described.$error">This information is required</span>
                  </v-col>
                </v-row>
                <hr/>

              </v-col>
            </v-row>
            <v-row v-if="rows.length>0">
              <v-col cols="12" class="text-right">
                <v-btn x-large text
                       class="ml-4">
                  Cancel
                </v-btn>
                <v-btn @click="save" x-large dark>
                  Submit
                </v-btn>

              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ProductService from "../../../../../services/product/ProductService";
import FedRegistrationService from "../../../../../services/candidate/fed-registration/FedRegistrationService";
import {email, required, requiredIf} from "vuelidate/lib/validators";
import AccountHolderService from "../../../../../services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import CandidateService from "@/services/candidate/CandidateService";

const candidate=new CandidateService();
const score=new ScoreService;
const accountHolder=new AccountHolderService;
const fedRegister=new FedRegistrationService();
const product=new ProductService();
export default {
  data(){
    return{
      no_of_candidate:'',
      types:[
        {text:'Theory exam',value:'online_exam'},
        {text:'Theory course',value:'online_course'},
      ],
      class_exams:[
        {text:'Yes',value:1},
        {text:'No',value:0},
      ],
      products:[],
      selectProduct:'',
      productSearch:'',
      rows:[],
      months:[
        {value:'01',text:'January'},
        {value:'02',text:'February'},
        {value:'03',text:'March'},
        {value:'04',text:'April'},
        {value:'05',text:'May'},
        {value:'06',text:'June'},
        {value:'07',text:'July'},
        {value:'08',text:'August'},
        {value:'09',text:'September'},
        {value:'10',text:'October'},
        {value:'11',text:'November'},
        {value:'12',text:'December'},
      ],
      years:[],
      days:[],
      genders:[
        {value:'male',text:'Male'},
        {value:'female',text:'Female'},
        {value:'non-binary',text:'Nonbinary'},
        {value:'self-described',text:'Prefer to self-describe'},
        {value:'not_to_say',text:'Prefer not to say'},
      ],
      preferred_pronouns:[
        {value:'she',text:' She/Her'},
        {value:'he',text:'He/Him'},
        {value:'them',text:'They/Them'},
        {value:'self-described',text:'Prefer to self-describe'},
      ],
      gender_self_described:'',
      prefer_to_described:'',
      accountHolderDetail:{},
      scoreId:'',
      scoreDetail:{},
      is_class_exam:0,
      type:'',
      continue_flag:false,
      finish_flag:false,
      show_summary_flag:false,

      candidates:[],
    }
  },
  methods:{
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    limitMax(){
      if(this.no_of_candidate > 15) {
        this.no_of_candidate=15;
        this.$snotify.error('You cannot add more than'+' '+this.no_of_candidate+' '+'Candidate');
      }else if (this.no_of_candidate < 0){
        this.no_of_candidate=0;
        this.$snotify.error('You cannot add less than'+' '+this.no_of_candidate+' '+'Candidate');
      }
    },
    //AccountHolder
    getAccountHolderDetail(){
      let email='freeexams@ameb.edu.au';
      accountHolder
          .searchByEmail(email)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;

            if(this.accountHolderDetail){
             /* this.checkBillingAddress();*/
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.findScoreDetail();
            }
          })
          .catch(err => {

          }).finally(() => {
        this.categoryLoading = false;
      });
    },
    //Score
    findScoreDetail(){
      if(this.scoreId){
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail=response.data.score;
            })
            .catch(err => {

            }).finally(() => {

        });
      }
    },
    addMoreCandidate(){
      this.show_summary_flag=false;
    },
    continueCandidate(){
      this.continue_flag=true;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.rows = [];
        for (let i = 0; i < this.no_of_candidate; i++) {
          this.rows.push(
              {
                'first_name': '',
                'middle_name': '',
                'last_name': '',
                'day': '',
                'month': '',
                'year': '',
                'gender': '',
                'gender_self_described': '',
                'preferred_pronouns': '',
                'prefer_to_described': '',
                'preferred_pronouns_flag': false
              })
        }
        if (this.rows.length > 0) {
          this.getDayList();
          this.getYearList();
        }
      }
    },
    getYearList() {
      let current_year=new Date().getFullYear();
      let selected_date=current_year-4;
      let year=[];
      for(let i = 0; i <= 100; i++){
        year.push(i);
      }
      if(year.length>0){
        year.forEach(y=>{
          this.years.push({'text':selected_date - y,'value':selected_date - y})
        })
      }
    },
    getDayList() {
      for(let i = 1; i < 32; i++){
        this.days.push(i);
      }
    },
    save(){
      this.continue_flag=false;
      this.finish_flag=true;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        let data = {};
        if (this.rows.length > 0) {
          this.rows.forEach((row, index) => {
            if(row.gender_self_described) {
              this.rows[index].gender = row.gender_self_described;
            }
            if (row.prefer_to_described) {
              this.rows[index].preferred_pronouns = row.prefer_to_described;
            }
            this.rows[index].date_of_birth = row.year + '-' + row.month + '-' + row.day;
          });
        }
        data['candidates'] = this.rows;

        data['is_class_exam']=this.is_class_exam;
        if (this.selectProduct) {
          data['product_id'] = this.selectProduct.id;
        }
        fedRegister
            .create(data)
            .then((response) => {
              this.resetForm();
              this.$snotify.success("Comp. enrolment enrolment added");

              if(response.data.candidate_ids){

                this.getCandidateByIds(response.data.candidate_ids);
              }
            })
            .catch(err => {

            })
            .finally(() => {
              this.continue_flag=false;
              this.finish_flag=false;
            });
      }
    },
    getCandidateByIds(candidateIds){
      candidate
          .getByIds({'candidate_ids':candidateIds})
          .then((response) => {
            this.candidates=response.data.data;
            this.show_summary_flag=true;
          })
          .catch((err) => {

          }).finally(() => {

      });
    },
    resetForm(){
      this.rows=[];
      this.no_of_candidate='';
      this.selectProduct='';
      this.productSearch='';
      this.type='',
      this.is_class_exam=0;
    },
    changeGender(index){

      if(this.scoreDetail && this.scoreDetail.preferred_pronoun_enabled){
        if(this.rows[index].gender=='non-binary' || this.rows[index].gender=='self-described' ){
          this.rows[index].preferred_pronouns_flag=true;
        }else{
          this.rows[index].preferred_pronouns_flag=false;
        }
      }else{
        this.rows[index].preferred_pronouns_flag=false;
      }

    },
    changeType(){
      this.productSearch='';
    }
  },
  mounted() {
      this.getAccountHolderDetail();
  },
  validations(){
    return this.rules;
  },
  watch: {
    productSearch(val) {
      if(this.type){
        let data={};
        data['name']=val;
        data['type']=this.type;
        product
            .searchByComplimentaryType(data)
            .then((response) => {
              this.products = response.data.products;
            })
            .catch(err => {

            })
            .finally(() => (this.isLoading = false))
      }

    },
  },
  computed:{
    rules(){
      if(this.continue_flag){
        return {
          no_of_candidate:{required},
          type:{required},
          selectProduct:{required},
        }
      }else if(this.finish_flag){
        return {
          rows: {
            $each: {
              first_name: {required},
              last_name: {required},
              day:{required},
              month:{required},
              year:{required},
              gender:{
                required: requiredIf(function (nestedModel) {
                  return this.scoreDetail && this.scoreDetail.gender_identity;
                })
              },
              preferred_pronouns: {
                required: requiredIf((obj) => {
                      return  obj.preferred_pronouns_flag && this.scoreDetail.preferred_pronoun_enabled;
                    }
                ),
              },
              /*   gender_self_described: {
                   required: requiredIf((obj) => {
                         return  obj.gender=='self-described' && this.scoreDetail && this.scoreDetail.gender_identity;;
                       }
                   ),
                 },*/
              gender_self_described:{
                required: requiredIf(function (nestedModel) {
                  return nestedModel.gender=='self-described' && this.scoreDetail && this.scoreDetail.gender_identity;
                })
              },
              prefer_to_described: {
                required: requiredIf(function (nestedModel) {
                  return nestedModel.preferred_pronouns_flag && nestedModel.preferred_pronouns=='self-described' && this.scoreDetail.preferred_pronoun_enabled;
                })
              },


            }
          },
        }
      }else{
        return {};
      }
    },
  }
}
</script>