import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class FedRegistrationService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/fed-registration';
    }
    create(data={}){
        let url = `${this.#api}/create`;
        return apiService.post(url, data);
    }
}